import './styles.scss';
import React, {memo, useEffect, useState, useRef} from 'react';
import CircleProgressSpecial from '../CircleProgressSpecial';

import {Link, navigate} from "gatsby";

import {
    sandClock,
} from "../../images";

type Props = {
    setLink: string
}

const SpecialLoading = ({setLink}: Props) => {

    const [isLoading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300);

    setTimeout(() => {
        setLoading1(false);
    }, 4000);

    setTimeout(() => {
        setLoading2(false);
    }, 10000);

    return (
        <>
            <div className="specLoading">
                <h2 className="specLoadingTitle">Just a moment! Creating an offer for you</h2>
                <div className="specLoadingGraph">
                    <CircleProgressSpecial
                        size={208}
                        strokeWidth={5}
                        percentage={100}
                        nextStep={`${setLink}`}
                        color="#FFD4AE">
                    </CircleProgressSpecial>
                </div>
                <div className="specLoadingList">
                    <ul>
                        <li>Collecting your starting level info</li>
                        <li className={`${loading1 ? 'loading' : ''}`}>Processing your intentions and goals</li>
                        <li className={`${loading2 ? 'loading' : ''}`}>Setting up a personal strategy</li>
                    </ul>
                </div>
            </div>
            
        </>
    )
}

export default memo(SpecialLoading);
