import './styles.scss';
import React, {memo, useEffect, useState} from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';

import {Link, navigate} from "gatsby";
import {toast, ToastContainer} from "react-toastify";
import useAnalytics from "../../hooks/useAnalytics";

import {
    reset
} from "../../images";

type Props = {
    id: string,
    fullLink: string,
    type: string,
}

const SpecialRegCorp = ({id, fullLink, type}: Props) => {

    // const isDisabled = false;
    const [isDisabled, setIsDisabled] = useState(true);
    const [email, setEmail] = useState('');
    const {initAnalytics, saveEmail, saveSubType, saveTrialPrice} = useAnalytics();

    const validateEmail = () => {
        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            saveEmail(email, type);
            localStorage.setItem('email', email);
            navigate(`/${fullLink}/loading-data`);
        } else{
            toast('Invalid e-mail address', {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const valideOnKeyup = (value:string) => {
        setEmail(value);
        if(value.length > 0){
            setIsDisabled(false);
        } else{
            setIsDisabled(true);
        }
    }

    const renderPage = () => {
        switch (id){
            case 'register-email':
                return <>
                    <div className="onBoardProgress">
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem`}></div>
                        <div className={`onBoardProgressItem`}></div>
                        <div className={`onBoardProgressItem`}></div>
                    </div>
                    <div className="onBoardRegForm">
                        <span className="onBoardRegFormTitle">Email Address</span>
                        <p className="onBoardRegFormText">To continue please enter your email address</p>
                        <div className="onBoardQuesInput onBoardQuesInputEmail">
                            <input 
                                type="email" 
                                onChange={(e) => valideOnKeyup(e.target.value)} 
                                placeholder="Email" 
                                // onKeyUp={(e) => onInputKeyup(e.currentTarget.value)} 
                                />
                        </div>
                    </div>
                    <div className="onBoardBottom">
                        <button 
                            id="pageCorp-corp-second-SignUpBtn"
                            className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}
                            onClick={() => validateEmail()}>Continue</button>
                        {/* <Link to={`/corp-main-onboard/confirm-email`} className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}>Continue</Link> */}
                    </div>
                </>;
            case 'confirm-email':
                return <>
                    <div className="onBoardProgress">
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem`}></div>
                        <div className={`onBoardProgressItem`}></div>
                    </div>
                    <div className="onBoardRegForm">
                        <span className="onBoardRegFormTitle">Verify your email</span>
                        <p className="onBoardRegFormText">Enter the code that has been sent to your email</p>
                        <div className="onBoardQuesCodeFile">
                            <ReactInputVerificationCode length={6} autoFocus />
                        </div>
                    </div>
                    <button className="onBoardReset">
                        <span>Resend code</span>
                        <img src={reset} alt="" />
                    </button>
                    <div className="onBoardBottom">
                        <Link to={`/corp-main-onboard/create-username`} className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}>Continue</Link>
                    </div>
                </>;
            case 'create-username':
                return <>
                    <div className="onBoardProgress">
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem`}></div>
                    </div>
                    <div className="onBoardRegForm">
                        <span className="onBoardRegFormTitle">Create a username</span>
                        <p className="onBoardRegFormText">This username will be used when you post to the AEER Platform community</p>
                        <div className="onBoardQuesInput onBoardQuesInputUsername">
                            <input type="text" placeholder="Username" 
                                // onKeyUp={(e) => onInputKeyup(e.currentTarget.value)} 
                                />
                        </div>
                    </div>
                    <div className="onBoardBottom">
                        <Link to={`/corp-main-onboard/create-password`} className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}>Continue</Link>
                    </div>
                </>;
            case 'create-password':
                return <>
                    <div className="onBoardProgress">
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem active`}></div>
                        <div className={`onBoardProgressItem active`}></div>
                    </div>
                    <div className="onBoardRegForm">
                        <span className="onBoardRegFormTitle">Create a password</span>
                        <p className="onBoardRegFormText">Please create a password with at least 8 characters</p>
                        <div className="onBoardQuesInput onBoardQuesInputPassword">
                            <input type="password" placeholder="Password" />
                        </div>
                    </div>
                    <div className="onBoardBottom">
                        <Link to={`/corp-main-onboard/loading-data`} className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}>Continue</Link>
                    </div>
                </>;
        }
    }

    return (
        <>
            {renderPage()}

            <ToastContainer/>
        </>
    )
}

export default memo(SpecialRegCorp);
